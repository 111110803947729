import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import DeprecatedSectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import TextStatementBase from '@vcc-www/text-statement';
import {
  maxBackgroundWidth12Columns,
  maxContentWidth12Columns,
} from '@vcc-www/constants/layout';
import TextStatementProps from './TextStatement.props';

type Props = TextStatementProps;

const TextStatement: React.FC<Props> = ({
  content,
  containerFullWidth = false,
  alignContent = 'center',
  titleElement = 'p',
  ...params
}) => {
  const { title, text, breakLine, disclaimer } = content;
  return (
    <DeprecatedSectionContained
      {...params}
      extend={sectionContainedOverride(containerFullWidth, alignContent)}
    >
      <TextStatementBase
        title={title}
        text={text}
        breakLine={breakLine}
        disclaimer={disclaimer}
        titleElement={titleElement}
        alignContent={alignContent}
      />
    </DeprecatedSectionContained>
  );
};

export default TextStatement;

const sectionContainedOverride = (
  containerFullWidth?: boolean,
  alignContent?: 'left' | 'center' | 'center-left',
): ExtendCSS => ({
  ...(containerFullWidth
    ? {
        fromL: {
          maxWidth: maxBackgroundWidth12Columns,
        },
        fromM: {
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
        },
      }
    : {}),
  ...(alignContent === 'center-left' && {
    fromM: {
      maxWidth: maxContentWidth12Columns / 2,
    },
  }),
});
